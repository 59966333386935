document.addEventListener('DOMContentLoaded', function () {

    //function to create pagination based on number of tr in tbody of tables with class="table-data" and create a list of page numbers in div with class="pagination" located in parent element
    const createPagination = function (table) {
        const tableRows = table.querySelectorAll('tbody tr');
        const tableRowsPerPage = 6;
        const tablePages = Math.ceil(tableRows.length / tableRowsPerPage);
        const pagination = table.parentElement.querySelector('.pagination');
        let paginationList = '';
    
        // Create a list of page numbers
        paginationList += `<ul>`;
        for (let i = 1; i <= tablePages; i++) {
            paginationList += `<li data-page="${i}">${i}</li>`;
        }
        paginationList += `</ul>`;
        
        // Onload add .active to current page number li element
        paginationList = paginationList.replace(`<li data-page="1">1</li>`, `<li data-page="1" class="active">1</li>`);
    
        // Add the list of page numbers to the div with class="pagination"
        pagination.innerHTML = paginationList;
    
        // Add a "hide" class to all TR in the tbody of the table
        tableRows.forEach(function (row) {
            row.classList.add('hide');
        });
    
        if (tableRows.length > 0) {
            // Remove the "hide" class from the first 10 TR in the tbody of the table
            for (let i = 0; i < Math.min(tableRowsPerPage, tableRows.length); i++) {
                tableRows[i].classList.remove('hide');
            }
        }
    
        // Add an event listener to the list of page numbers (the <ul> element)
        pagination.querySelector('ul').addEventListener('click', function (e) {
            if (e.target.tagName === 'LI') {
                // Get page number from the Li's data-page attribute
                const pageNumber = e.target.getAttribute('data-page');
    
                // Remove the "hide" class from all TR in the tbody of the table
                tableRows.forEach(function (row) {
                    row.classList.remove('hide');
                });
    
                // Add a "hide" class to all TR in the tbody of the table
                tableRows.forEach(function (row) {
                    row.classList.add('hide');
                });
    
                // Remove the "hide" class from the TRs for the selected page
                for (let i = (pageNumber - 1) * tableRowsPerPage; i < Math.min(pageNumber * tableRowsPerPage, tableRows.length); i++) {
                    if (tableRows[i]) {
                        tableRows[i].classList.remove('hide');
                    }
                }
    
                // Remove the "active" class from all <li> elements in the list of page numbers
                pagination.querySelectorAll('li').forEach(function (li) {
                    li.classList.remove('active');
                });
    
                // If .prev or .next li is clicked, add .active class to the li element with innerHTML equal to the current page number
                if (e.target.classList.contains('prev') || e.target.classList.contains('next')) {
                    pagination.querySelectorAll('li').forEach(function (li) {
                        if (li.innerHTML === pageNumber) {
                            li.classList.add('active');
                        }
                    });
                } else {
                    // If a page number li is clicked, add .active class to the clicked li element
                    e.target.classList.add('active');
                }
    
                // Add .prev li element if it doesn't exist before the first page number
                if (!pagination.querySelector('.prev')) {
                    pagination.querySelector('ul').insertAdjacentHTML('afterbegin', `<li data-page="" class="prev"><</li>`);
                }
    
                // Add .next li element if it doesn't exist after the last page number
                if (!pagination.querySelector('.next')) {
                    pagination.querySelector('ul').insertAdjacentHTML('beforeend', `<li data-page="" class="next">></li>`);
                }
    
                // Update the data-page attribute integer of the .prev and .next li elements to -1 and +1 of the current page number
                pagination.querySelector('.prev').setAttribute('data-page', parseInt(pageNumber) - 1);
                pagination.querySelector('.next').setAttribute('data-page', parseInt(pageNumber) + 1);
    
                if (tablePages > 10) {
                    // Hide all pagination li elements where the innerHTML is less than the current page number minus 3 or greater than the current page number plus 3
                    pagination.querySelectorAll('li').forEach(function (li) {
                        if (li.innerHTML < parseInt(pageNumber) - 3 || li.innerHTML > parseInt(pageNumber) + 3) {
                            li.classList.add('hide');
                        } else {
                            li.classList.remove('hide');
                        }
    
                        // Always show the first and last page numbers
                        if (li.innerHTML === '1' || li.innerHTML === tablePages.toString()) {
                            li.classList.remove('hide');
    
                            // Add ellipsis to classlist of li elements with data-page attribute less than the current page number minus 3 or greater than the current page number plus 3
                            if (li.innerHTML < parseInt(pageNumber) - 4) {
                                li.classList.add('ellipsis-right');
                            } else {
                                li.classList.remove('ellipsis-right');
                            }
    
                            if (li.innerHTML > parseInt(pageNumber) + 4) {
                                li.classList.add('ellipsis-left');
                            } else {
                                li.classList.remove('ellipsis-left');
                            }
                        }
                    });
                }    
    
                // Show the .prev and .next li elements if the current page number is not the first or last page
                if (pageNumber > 1) {
                    pagination.querySelector('.prev').classList.remove('hide');
                } else {
                    pagination.querySelector('.prev').classList.add('hide');
                }
    
                if (pageNumber < tablePages) {
                    pagination.querySelector('.next').classList.remove('hide');
                } else {
                    pagination.querySelector('.next').classList.add('hide');
                }
            }
        });
    };
    

    // Iterate through all tables with class "table-data" and apply pagination
    const tables = document.querySelectorAll('.table-data');
    tables.forEach(function (table) {
        createPagination(table);
    });

    // If someone types in the input field of any class="table-search-form"
    const searchInput = document.querySelectorAll('.table-search-form input');

    const searchBtn = document.querySelectorAll('.table-search-form .search-icon');

    //if searchBtn is clicked and input field is empty, add classlist "required" to input field and remove it after 3 seconds
    searchBtn.forEach(function (btn) {
        btn.addEventListener('click', function () {
            const input = btn.parentElement.querySelector('input');
            if (!input.value) {
                input.classList.add('required');
                setTimeout(function () {
                    input.classList.remove('required');
                }, 3000);
            }
        });
    });

    searchInput.forEach(function (input) {
        input.addEventListener('input', function () {
            // Get the closest .table-wrapper, .table-data, and .pagination elements to the input field
            const tableWrapper = input.closest('.table-wrapper');
            const tableData = tableWrapper.querySelector('.table-data');
            const pagination = tableWrapper.querySelector('.pagination');

            //if input.value is greater than 2 characters
            if (input.value.length > 1) {
                // Hide pagination div if someone types in the input field
                pagination.classList.add('hide');

                // Only allow letters, numbers, spaces, and hyphens in the input field
                input.value = input.value.replace(/[^a-zA-Z0-9\s-]/g, '');

                // Get all TR elements within the closest .table-data
                const tableRows = tableData.querySelectorAll('tbody tr');
                tableRows.forEach(function (row) {
                    !row.textContent.toLowerCase().includes(input.value.toLowerCase()) ? row.classList.add('hide') : row.classList.remove('hide');
                });

                //if no results are found, display a TR with class="noresults" that says "No results found" in the tbody of the table
                if (tableData.querySelectorAll('tbody tr:not(.hide)').length === 0) {
                    const noResultsRow = document.createElement('tr');
                    noResultsRow.classList.add('noresults');
                    noResultsRow.innerHTML = `<td colspan="100%">No results found</td>`;
                    tableData.querySelector('tbody').appendChild(noResultsRow);
                }
            
            } else {
                //if someone deletes the input field, remove all TRs with class="noresults" if they exist
                const noResultsRows = tableData.querySelectorAll('tbody tr.noresults');
                if (noResultsRows.length > 0) {
                    noResultsRows.forEach(function (row) {
                        row.remove();
                    });
                }
                
                //run createPagination if someone deletes the input field and remove hide class if it was hidden
                pagination.classList.remove('hide');

                //rebuild pagination
                createPagination(tableData);
            }
        });
    });
});
